
import 'react-multi-carousel/lib/styles.css';

import 'animate.css';


export const Skills = () => {


  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">

            </div>
        </div>   
    </section>
  )
}
